import { Tag } from '@phosphor-icons/react'
import clsx from 'clsx'
import type { IKeyValueMap } from 'mobx'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import AdminPostTagsFilter from '@/components/admin/posts/filters/AdminPostTagsFilter'
import UserSideBoardDescription from '@/components/posts/postList/UserSideBoardDescription'
import RoadmapSingleKanban from '@/components/roadmap/multipleRoadmap/views/RoadmapSingleKanban'
import RoadmapBoardList from '@/components/roadmap/RoadmapBoardList'
import type { IKanbanLane } from '@/components/shared/components/kanban/types'
import { EmbedShowWrapper } from '@/components/shared/components/wrapper/EmbedWrapper'
import Button from '@/components/shared/ui/Button'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import {
  POST_LIST_PAGINATION_THRESHOLD,
  STATUS_COLORS_MAP,
} from '@/config/appConstants'
import { STATUS_COLORS } from '@/config/module/colorConstant'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { isSameArray } from '@/lib/helpers/dataHelpers'
import {
  generateMonthlyArray,
  geneterateQuaterlyArray,
  timezoneInMinutes,
} from '@/lib/helpers/dateHelpers'
import { postToRoadmapItem } from '@/lib/helpers/modules/postHelper'
import { isHTMLContentEmpty } from '@/lib/helpers/stringHelpers'
import { getAdminPosts, getOptimizedPosts } from '@/models/Post'
import type { IBaseStatuses } from '@/types/post'
import type { IGroupMechanism, IRoadmapItem } from '@/types/roadmap'

const NewPostButton = dynamic(
  () => import('@/components/posts/newPost/NewPostButton'),
  { ssr: false }
)
const RoadmapLaneGroupSelect = dynamic(
  () => import('@/components/roadmap/RoadmapLaneGroupSelect'),
  { ssr: false }
)

const parentStatus = Object.keys(STATUS_COLORS_MAP)
interface IRoadmapLane extends IKanbanLane {
  filterKey?: string
  type: string
  visible_in_roadmap?: boolean
}

export default function RoadmapPage() {
  const t = useTranslations()
  const [items, setItems] = useState<IRoadmapItem[]>([])
  const {
    isAdmin,
    organizationCustomization,
    customStatus = {},
    custom_states,
    organizationSetting,
    buckets = [],
  } = useContext(HNContext)
  const router = useRouter()
  const { boardSlug, type, tag_id } = router.query as {
    boardSlug?: string
    type?: IGroupMechanism
    tag_id?: string[]
  }

  const [laneLoading, setLaneLoading] = useState<IKeyValueMap>({})
  const [laneCanFetchMore, setLaneCanFetchMore] = useState<IKeyValueMap>({})
  const lanePage = useRef<IKeyValueMap>({})
  const [edit, setEdit] = useState(false)
  const [groupMechnism, setGroupMechnism] = useState<IGroupMechanism>(
    type ||
      (organizationCustomization?.roadmap_view?.[0] as IGroupMechanism) ||
      'status'
  )
  const currentBoardSlug = useRef<string | null | undefined>(boardSlug)
  const currentTags = useRef<string[] | null | undefined>(tag_id)

  const readOnlyLane = useMemo(() => {
    return groupMechnism === 'quarterly' || groupMechnism === 'monthly'
  }, [groupMechnism])

  const stateOrStatusLanes: IRoadmapLane[] = useMemo(() => {
    let laneValues = []

    if (groupMechnism === 'quarterly')
      return geneterateQuaterlyArray(4).map((quarter, idx) => ({
        id: quarter.value,
        name: quarter.label,
        color: STATUS_COLORS[idx + 2]?.hex,
        filterKey: 'quarterly_etc',
        type: 'quarterly',
        visible_in_roadmap: true,
      }))

    if (groupMechnism === 'monthly')
      return generateMonthlyArray(4).map((month, idx) => ({
        id: month.value,
        name: month.label,
        color: STATUS_COLORS[idx + 2]?.hex,
        filterKey: 'monthly_etc',
        type: 'monthly',
        visible_in_roadmap: true,
      }))

    if (groupMechnism === 'state') {
      laneValues = (custom_states || [])
        .map((state) => ({
          ...state,
          id: state.slug,
          name: state.name,
          stateId: state.id,
          parentId: state.status,
          value: state.slug,
          filterKey: 'state',
          visible_in_roadmap: state.visible_in_roadmap !== false,
          type: 'state',
          position:
            state.position +
            (parentStatus.indexOf(state.status.toString()) + 1) * 10,
        }))
        .sort((a, b) => (a.position || 0) - (b.position || 0))
    } else {
      laneValues = Object.keys(customStatus)
        .filter((item) => {
          if (item === 'all' || item === 'hidden') return false
          if (edit) return true
          return true
        })
        .map((key) => ({
          id: key,
          filterKey: 'status',
          name:
            organizationSetting?.[`roadmap_${key as IBaseStatuses}_name`] ||
            customStatus[key],
          title:
            organizationSetting?.[`roadmap_${key as IBaseStatuses}_name`] ||
            customStatus[key],
          type: 'status',
          visible_in_roadmap:
            organizationSetting?.[`roadmap_${key as IBaseStatuses}_view`],
          canEditName: true,
        }))
    }
    return laneValues
  }, [customStatus, edit, organizationSetting, groupMechnism])

  const lanes: IRoadmapLane[] = useMemo(() => {
    return ((stateOrStatusLanes || []) as any[])?.filter(
      (lane: any) => lane.visible_in_roadmap || edit
    )
  }, [stateOrStatusLanes])

  const updateLoading = (laneId: string, loading: boolean) => {
    if (!laneId) return
    setLaneLoading((prev) => ({ ...prev, [laneId.toString()]: loading }))
  }

  const fetchData = useCallback(
    (lane: { id: string; filterKey?: string }) => {
      const { filterKey = 'status', id: laneId } = lane
      if (!filterKey) return
      const getPostsFunc = isAdmin ? getAdminPosts : getOptimizedPosts

      const page = lanePage.current?.[laneId.toString()] || 1
      updateLoading(laneId, true)
      getPostsFunc({
        [filterKey]: lane.id,
        bucket_slug: boardSlug,
        page,
        no_pagination: true,
        sort: organizationCustomization?.roadmap_default_sort,
        roadmap: true,
        show_hidden: isAdmin,
        timezone: timezoneInMinutes(),
        tag_id,
      })
        .then((posts) =>
          posts.map(postToRoadmapItem).map((post) => ({
            ...post,
            laneId,
          }))
        )
        .then((posts) => {
          setLaneCanFetchMore((prev) => ({
            ...prev,
            [laneId.toString()]: posts.length >= POST_LIST_PAGINATION_THRESHOLD,
          }))
          lanePage.current[laneId.toString()] = page + 1

          setItems((oldPosts) => [...oldPosts, ...posts])
        })
        .finally(() => updateLoading(laneId, false))
    },
    [
      lanePage,
      organizationCustomization,
      organizationSetting,
      isAdmin,
      boardSlug,
      tag_id,
    ]
  )

  const handleUpdateItem = (item: IRoadmapItem) => {
    setItems((prev) =>
      prev.map((prevItem) =>
        +prevItem.id === +item.id ? { ...prevItem, ...item } : prevItem
      )
    )
  }

  const handlePostUpdate = (post: IKeyValueMap) => {
    setItems((prev) =>
      prev.map((prevItem) =>
        prevItem.slug === post.slug ? { ...prevItem, ...post } : prevItem
      )
    )
  }

  const handleDelete = (item: IRoadmapItem) => {
    setItems((prev) => prev.filter((prevItem) => prevItem.id !== item.id))
  }

  const handleGroupingChange = (selected: ISelectOption) => {
    const { value } = selected as ISelectOption
    lanePage.current = {}
    setItems([])
    setGroupMechnism(value)
    router.push(
      {
        query: { ...router.query, type: value },
      },
      undefined,
      {
        shallow: true,
      }
    )
  }

  const getCurrentBoardId = () => buckets?.find((b) => b.name === boardSlug)?.id

  useEffect(() => {
    const ct = Array.isArray(currentTags.current)
      ? currentTags.current
      : [currentTags.current]
    const newTag = Array.isArray(tag_id) ? tag_id : [tag_id]

    if (currentBoardSlug.current !== boardSlug || !isSameArray(ct, newTag)) {
      setItems([])
      lanePage.current = {}
    }

    currentBoardSlug.current = boardSlug
    currentTags.current = tag_id

    lanes.map((status) => {
      fetchData(status)
      return true
    })
  }, [lanes, boardSlug, tag_id])

  return (
    <>
      <div className='mx-auto max-w-7xl px-4 py-2 empty:hidden'>
        <EmbedShowWrapper>
          <div className='flex items-center justify-between space-x-2'>
            <UserSideBoardDescription
              rightComponents={<NewPostButton boardId={getCurrentBoardId()} />}
            />
          </div>
        </EmbedShowWrapper>
        {!isAdmin && (
          <div className='flex w-full items-center justify-between'>
            <div className='flex flex-col gap-2'>
              <h3 className='text-lg font-semibold'>
                {organizationSetting?.roadmap_plural_name}
              </h3>
              {!isHTMLContentEmpty(
                organizationSetting?.roadmap_description
              ) && (
                <div
                  className='prose-xs w-full prose-a:font-normal prose-a:text-gray11 hover:prose-a:text-primary hover:prose-a:no-underline'
                  dangerouslySetInnerHTML={{
                    __html: organizationSetting?.roadmap_description || '',
                  }}
                />
              )}
            </div>
            <div className='flex items-center gap-2'>
              <RoadmapLaneGroupSelect
                groupMechnism={groupMechnism}
                onChange={handleGroupingChange}
              />
              {!organizationSetting?.hide_tags && (
                <AdminPostTagsFilter
                  size='sm'
                  filters={{
                    tag_id: router.query.tag_id,
                    board: true,
                    bucket_id: getCurrentBoardId(),
                  }}
                  behaviour='filter'
                  onChange={(_key, value) => {
                    router.push({
                      pathname: router.pathname,
                      query: { ...router.query, tag_id: value },
                    })
                  }}
                  placeholder={t('common.selectTag')}
                  clearable
                  showBoardTags
                  loadFromCache
                  itemFilter={(item) => item.id !== 'untagged'}
                  selectProps={{
                    className: '!py-1 !px-2',
                  }}
                  renderTrigger={
                    tag_id
                      ? undefined
                      : () => {
                          return (
                            <Button
                              as='span'
                              variant='outline'
                              icon={<Tag weight='fill' />}
                              size='sm'
                              rounded='full'
                            />
                          )
                        }
                  }
                />
              )}
              <RoadmapBoardList />
            </div>
          </div>
        )}
      </div>

      {isAdmin && (
        <RoadmapLaneGroupSelect
          mountOnHeader
          groupMechnism={groupMechnism}
          onChange={handleGroupingChange}
        />
      )}
      <div className='overflow-x-auto'>
        <RoadmapSingleKanban
          items={items}
          lanes={lanes}
          onUpdate={handleUpdateItem}
          onPostUpdate={handlePostUpdate}
          onDelete={handleDelete}
          readonly={!isAdmin || readOnlyLane}
          laneLoading={laneLoading}
          onCreate={() => {}}
          onTagPost={() => {}}
          type='default'
          groupMechanism={groupMechnism}
          onEditToggle={setEdit}
          laneCanFetchMore={laneCanFetchMore}
          onFetchMore={(lane) => fetchData(lane)}
          useCustomStateLanes={organizationSetting?.show_custom_states}
          className={clsx(
            isAdmin
              ? 'mx-auto !max-h-[calc(100vh-54px)] max-w-max'
              : 'mx-auto !max-h-[calc(100vh-175px)] max-w-max'
          )}
          classNames={{
            lane: {
              laneContainer: isAdmin
                ? 'max-h-[calc(100vh-140px)] min-h-[calc(100vh-140px)] '
                : 'pb-12 max-h-[calc(100vh-250px)] min-h-[calc(100vh-250px)] ',
            },
          }}
        />
      </div>
    </>
  )
}
