import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import clsxm from '@/lib/clsxm'
import type { IBoardDetails } from '@/types/board'
import type { IOrganizationData } from '@/types/organization'

interface IPropTypes {
  rightComponents?: React.ReactNode
}

const MAX_HEIGHT = 200
export default function UserSideBoardDescription({
  rightComponents,
}: IPropTypes) {
  const router = useRouter()
  const t = useTranslations('buttons')
  const [readMore, setReadMore] = useState(false)
  const { buckets, global_submission_form } = useContext(HNContext) as {
    organization: IOrganizationData
    buckets: IBoardDetails[]
    global_submission_form: any
  }
  const [isMinimized, setIsMinimized] = useState<boolean>(false)
  const descriptionRef = useRef<HTMLDivElement>(null)
  const initialLoad = useRef<boolean>(false)

  const activeBoard = useMemo<IBoardDetails>(() => {
    if (router.query.boardSlug)
      return buckets?.find((b) => b.name === router.query.boardSlug)
    return global_submission_form?.hello_sentence
      ? global_submission_form
      : buckets?.find((b) => b.default)
  }, [router.query, buckets])

  useEffect(() => {
    if (
      !initialLoad.current &&
      descriptionRef.current &&
      descriptionRef.current.offsetHeight > MAX_HEIGHT
    ) {
      setIsMinimized(true)
      initialLoad.current = true
    }
  }, [descriptionRef.current])

  return (
    <>
      <div className='relative w-full flex-1 items-start justify-between sm:flex md:mx-0 md:flex-row'>
        <div
          className={clsxm(
            'prose m-0 w-full !max-w-none whitespace-pre-line text-sm dark:prose-invert prose-a:font-normal prose-a:text-primary hover:prose-a:text-primary hover:prose-a:underline'
          )}
          dangerouslySetInnerHTML={{
            __html: activeBoard?.hello_sentence
              ? activeBoard.hello_sentence
              : global_submission_form?.hello_sentence,
          }}
        ></div>
        {isMinimized && (
          <button
            onClick={() => setReadMore(!readMore)}
            className='absolute bottom-3 left-1/2 flex -translate-x-1/2 items-center justify-center rounded-lg border-[0.5px] border-gray7 bg-gray2 px-2 py-0.5 text-xs text-gray10'
          >
            {!isMinimized ? t(`read_less`) : t(`read_more`)}
          </button>
        )}
        <div className='mt-2 empty:hidden sm:mt-0'>{rightComponents}</div>
      </div>
    </>
  )
}
