import { useRouter } from 'next/router'
import React, { useContext, useMemo } from 'react'

import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'

export default function RoadmapBoardList() {
  const t = useTranslations()

  const router = useRouter()
  const { type } = router.query
  const { buckets = [] } = useContext(HNContext)

  const boardOptions = useMemo(
    () => [
      {
        value: '',
        group: '',
        label: t('messages.showAll'),
      },
      ...buckets
        .filter((b) => !b.disable_roadmap_view)
        .map((board) => ({
          value: board.name,
          label: board.display_name,
          group: board.private
            ? t('common.modules.boards.private.title')
            : t('common.modules.boards.public.title'),
        })),
    ],
    [buckets]
  )

  return (
    <div>
      <Select
        size='xs'
        options={boardOptions}
        placeholder='Select a board'
        className='!max-w-[300px]'
        group
        defaultValue={boardOptions.filter(
          (board) => board.value === (router.query.boardSlug || '')
        )}
        onChange={(selectedBoard) => {
          const { value } = selectedBoard as ISelectOption
          if (value === '') {
            return router.push({
              pathname: `/roadmap`,
              query: {},
            })
          }
          return router.push({
            pathname: `/roadmap/b/[boardSlug]`,
            query: { boardSlug: value, type },
          })
        }}
      />
    </div>
  )
}
